import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Helmet } from 'react-helmet'
import Layout from '../components/Layout'
import ContactForm from '../components/ContactForm'
import { IoAccessibilityOutline, IoBusinessOutline, IoMailOutline, IoNewspaperOutline, IoPrintOutline } from 'react-icons/io5'

const Home = () => {

  const data = useStaticQuery(graphql`
    {
      allContentfulWhatsNew(sort: {order: DESC, fields: date}, limit: 20)  {
        nodes {
          news
          date(formatString: "YYYY. MM. DD.")
        }
      }
      p1: contentfulMainPhotos(priority: {eq: 1}) {
        title
        photo {
          gatsbyImageData
        }
      }
      p2: contentfulMainPhotos(priority: {eq: 2}) {
        title
        photo {
          gatsbyImageData
        }
      }
      p3: contentfulMainPhotos(priority: {eq: 3}) {
        title
        photo {
          gatsbyImageData
        }
      }
      p4: contentfulMainPhotos(priority: {eq: 4}) {
        title
        photo {
          gatsbyImageData
        }
      }
      p5: contentfulMainPhotos(priority: {eq: 5}) {
        title
        photo {
          gatsbyImageData
        }
      }
      p6: contentfulMainPhotos(priority: {eq: 6}) {
        title
        photo {
          gatsbyImageData
        }
      }
      p7: contentfulMainPhotos(priority: {eq: 7}) {
        title
        photo {
          gatsbyImageData
        }
      }
    }
  `)

  const whatsNew = data.allContentfulWhatsNew.nodes
  const textProductionDepartment = ['macom planning制作事業部は、スポーツアパレル、ファッション分野を中心に、グローバルなクリエイティブワークを提供するブランディング・エージェンシーです。', '私たちは、クライアントとビジョンを共有した上で、独自のブランディングメソッドを提案、展開し、幾多のブランドをサポートしてきました。', 'macom planningの得意とする写真、演出、映像などの第一線で活躍するクリエイターとのコラボレーションによるメッセージ性の高いアートディレクション、スキルを有したWEBソリューションをメインに、企業のビジョン及びブランディングを基にデザイン表現を具体化し、広告/キャンペーン/WEB/セールスプロモーションツール/パッケージ/コマーシャル/イベント等あらゆるソリューションの組み合わせによるトータルなクリエイティブワークをクライアントやユーザーに提供します。']
  const textWelfareDepartment = ['その人が生きていくことの糧になることを見つけ、その活力、エネルギーが削がれないようにサポートする“を支援軸とし、障害者総合支援法において障害者の「働く権利」「自立する心」を重要な支援サポートと位置付けます。', 'また各ブランド毎の強化支援を行うことで、“できる、やれる”こころを養い、自己肯定感を高める、質の高い支援サポートの提供することを目的とする。', '全スタッフは上記の支援理念と施設毎の支援ガイドラインの支援内容総論を心と頭と体で理解し、施設毎の取組みに社会的役割と意義を感じ、マコム・プラニング株式会社のサービス業としての福祉事業を全うします。']

  const companyProfileArray = [
    {
      title: '会社名',
      content: ['マコム・プラニング株式会社'],
    },
    {
      title: '設立',
      content: ['1970年12月'],
    },
    {
      title: '取引銀行',
      content: ['りそな銀行 船場支店 当座預金', 'りそな銀行 船場支店 普通預金', '三菱UFJ銀行 船場中央支店 当座預金'],
    },
    {
      title: '沿革',
      content: [
        '1970年12月 マコム・プラニング株式会社設立（資本金100万円）',
        '1981年3月 増資マコム・プラニング株式会社（資本金700万円）',
        '1995年5月 増資マコム・プラニング株式会社（資本金1,000万円）',
      ],
    },
    {
      title: '本社所在地',
      content: ['大阪市北区東天満2-9-2 AXIS南森町ビル別館5階'],
    },
    {
      title: '電話番号',
      content: ['06-6881-1096'],
    },
    {
      title: '代表者',
      content: ['弘中英一', '鍵谷篤'],
    },
    {
      title: '広告制作事業部 事業内容',
      content: [
        '広告・販売促進に関するプランニングおよびデザイン制作',
        '店舗リーシング業務/店舗設計企画/施工管理',
        '他業種ブランドとのタイアップ企画及び交渉',
        'ホームページ/コマーシャルの企画・制作',
        '店舗ディスプレイ（商品DMとの連携したVMD計画）',
        'スチール、動画撮影ディレクション'
      ],
    },
    {
      title: '福祉事業部 事業内容',
      content: [
        '放課後等デイサービス【このこのアート】運営',
        '放課後等デイサービス【とろんこアカデミー】運営',
        '多機能型障がい者施設【Wickアカデミー】運営',
        '個別支援計画書管理システム【bansou】の販売、運用、管理',
      ],
    },
    {
      title: '制作事業部の主なクライアント',
      content: [
        'デサントジャパン株式会社',
        '株式会社デサント',
        '田中貴金属ジュエリー株式会社',
        '株式会社ニューバランスJapan',
        'バレンチノジャパン株式会社',
        '株式会社クルード',
        '株式会社オオツキプランニング',
        'マルベリージャパン株式会社',
        '（順不同）',
      ],
    },
  ]


  return (
    <>
      <Helmet
        title="マコム・プラニング公式サイト"
        description="マコム・プラニングの公式サイトです。福祉事業のとろんこアカデミー運営・福祉施設管理アプリBansouの提供、そして制作事業としてスポーツウェアやアパレル会社へのデザイン提供等も行っております。"
      />
      <Layout>
        <section className="bg-gradient-to-br from-orange-200 via-white to-pink-100 pt-[4rem] px-4 md:px-10 pb-20">
          <h1 className="pb-20 md:pb-32 tracking-[0.57rem] font-bold">マコム・プラニング株式会社</h1>
          <div className="flex flex-wrap md:flex-nowrap max-w-6xl mx-auto">
            <div className="text-center md:text-left ">
              <h2 className="font-extrabold text-6xl sm:text-7xl">関わる<br />全ての人の<span className="border-b-8 border-orange-300"><br />笑顔</span>を<br />創造します</h2>
              <div className="mt-10 mb-4 flex gap-x-4 justify-center md:justify-start">
                <button><Link className="rounded-full py-2 px-4 flex group font-light bg-orange-200" to="/production"><IoPrintOutline className='my-auto mr-1 group-hover:animate-bounce' />制作事業部</Link></button>
                <button><Link className="rounded-full py-2 px-4 flex group font-light bg-pink-200" to="/welfare"><IoAccessibilityOutline className='my-auto mr-1 group-hover:animate-bounce' />福祉事業部</Link></button>
              </div>
              <p className="text-gray-700">マコム・プラニングは2つの事業軸を持つことでユニークなビジネスワークを可能にし、関わる全ての人の笑顔を創造しています</p>
            </div>
            <div className="flex gap-2 flex-wrap mx-auto mt-10 justify-center md:-mt-10">
              <GatsbyImage className="w-36 md:w-40 lg:w-60 h-36 md:h-40 lg:h-60  rounded-lg hover:scale-125 duration-1000 ease-in-out hover:z-40 hover:-translate-y-6 overflow-hidden" image={getImage(data.p2.photo.gatsbyImageData)} alt={data.p2.title} />
              <GatsbyImage className="w-36 md:w-40 lg:w-60 h-36 md:h-40 lg:h-60  rounded-lg hover:scale-125 duration-1000 ease-in-out hover:z-40 hover:-translate-y-6 overflow-hidden" image={getImage(data.p6.photo.gatsbyImageData)} alt={data.p6.title} />
              <GatsbyImage className="w-36 md:w-40 lg:w-60 h-36 md:h-40 lg:h-60   rounded-lg hover:scale-125 duration-1000 ease-in-out hover:z-40 hover:-translate-y-6 overflow-hidden" image={getImage(data.p3.photo.gatsbyImageData)} alt={data.p3.title} />
              <GatsbyImage className="w-36 md:w-40 lg:w-60 h-36 md:h-40 lg:h-60   rounded-lg hover:scale-125 duration-1000 ease-in-out hover:z-40 hover:-translate-y-6 overflow-hidden" image={getImage(data.p5.photo.gatsbyImageData)} alt={data.p5.title} />
            </div>
          </div>
        </section>

        {/* What's New */}
        <div className="bg-gradient-to-bl from-pink-100 via-white to-orange-50 py-16 px-4 md:px-10">
          <section className="max-w-6xl mx-auto text-center group">
            <IoNewspaperOutline className='my-auto mx-auto group-hover:animate-bounce duration-200 group-hover:text-pink-300 text-5xl' />
            <h2 className="font-bold text-6xl sm:text-8xl">News</h2>
            <p className="text-sm font-light"><span className="mx-auto group-hover:border-b-8 border-pink-300 duration-500">マコム・プラニングの最新のニュース</span></p>
          </section>
          <ul className="pt-10 px-4 grid justify-center gap-y-4">
            {whatsNew.map((w, index) => {
              return (
                <li className="" key={index}>
                  <p className={index % 2 === 0 ? "text-orange-300 text-sm" : "text-pink-300 text-sm"}>
                    {w.date}
                  </p>
                  <p className={index % 2 === 0 ? "hover:border-b-8 border-pink-300 duration-500 hover:font-bold" : "hover:border-b-8 border-orange-300 duration-500 hover:font-bold"}>
                    {w.news}
                  </p>
                </li>
              )
            })}
          </ul>
        </div>
        {/* 2 Departments */}
        <div className="grid md:grid-cols-2 gap-x-10 gap-y-20 bg-gradient-to-br from-orange-50 via-white to-pink-100 py-16 px-4 md:px-10">
          <div className="mx-auto hover:shadow-xl duration-500 p-2 rounded-lg">
            <section className="mx-auto text-center group">
              <IoPrintOutline className='my-auto mx-auto group-hover:animate-bounce duration-200 group-hover:text-orange-300 text-5xl' />
              <div className="">
                <p className="font-bold text-6xl sm:text-7xl">Production</p>
                <h2 className="text-sm font-light"><span className="mx-auto group-hover:border-b-8 border-orange-300 duration-500">服飾のデザインや展示会を手掛ける マコム・プラニングの制作事業部</span></h2>
              </div>
            </section>
            <GatsbyImage className='mt-4 rounded-lg h-96 hover:scale-110 duration-1000 ease-in-out hover:z-40 hover:-translate-y-6' image={getImage(data.p1.photo.gatsbyImageData)} alt="制作事業の写真" />
            <div className="text-gray-700 max-w-xl mx-auto grid gap-y-2 mt-3">
              {textProductionDepartment.map((t, index) => {
                return (
                  <p className='text-sm' key={index}>{t}</p>
                )
              })}
            </div>
            <Link className="ring-1 font-light ring-orange-300 hover:bg-orange-300 hover:text-white duration-300 py-2 px-4 my-4 grid justify-center rounded-full" to="/production" id='toProduction'>制作事業部の詳細</Link>
          </div>
          <div className="mx-auto hover:shadow-xl duration-500 p-2 rounded-lg">
            <section className="mx-auto text-center group">
              <IoAccessibilityOutline className='my-auto mx-auto group-hover:animate-bounce duration-200 group-hover:text-pink-300 text-5xl' />
              <div className="">
                <p className="font-bold text-6xl sm:text-7xl">Welfare</p>
                <h2 className="text-sm font-light"><span className="mx-auto group-hover:border-b-8 border-pink-300 duration-500">障害者の社会的自立をお手伝いする マコム・プラニングの福祉事業部</span></h2>
              </div>
            </section>
            <GatsbyImage className='mt-4 rounded-lg h-96 hover:scale-110 duration-1000 ease-in-out hover:z-40 hover:-translate-y-6' image={getImage(data.p4.photo.gatsbyImageData)} alt="福祉事業の写真" />
            <div className="text-gray-700 max-w-xl mx-auto grid gap-y-2 mt-3">
              {textWelfareDepartment.map((t, index) => {
                return (
                  <p className='text-sm' key={index}>{t}</p>
                )
              })}
            </div>
            <Link className="ring-1 font-light ring-pink-300 hover:bg-pink-300 hover:text-white duration-300 py-2 px-4 my-4 grid justify-center rounded-full" to="/welfare" id='toWelfare'>福祉事業部の詳細</Link>
          </div>
        </div>

        {/* Company Information */}
        <section id="company" className="bg-gradient-to-bl from-pink-100 via-white to-white py-16 px-4 md:px-10">
          <section className="mx-auto text-center group">
            <IoBusinessOutline className='my-auto mx-auto group-hover:animate-bounce duration-200 group-hover:text-gray-500 text-5xl' />
            <div className="">
              <p className="font-bold text-6xl sm:text-7xl">Company</p>
              <h2 className="text-sm font-light mt-1"><span className="mx-auto group-hover:border-b-8 border-gray-500 duration-500">会社情報</span></h2>
            </div>
          </section>
          <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-y-4 rounded-lg gap-x-4 mt-10 max-w-6xl sm:mx-auto">
            {
              companyProfileArray.map((c, index) => {
                return (
                  <div key={index} className="hover:shadow-lg duration-500 p-2 group">
                    <h3 className="text-xl font-semibold mb-2">{c.title}</h3>
                    {
                      c.content.map((co, i) => {
                        return (
                          <p className="text-gray-500 text-sm" key={i}>{co}</p>
                        )
                      }
                      )
                    }
                  </div>
                )
              }
              )
            }
          </div>
          <div id="enquiry" className="mt-20 max-w-2xl mx-auto">
            <section className="mx-auto text-center group mb-8">
              <IoMailOutline className='my-auto mx-auto group-hover:animate-bounce duration-200 text-5xl' />
              <div className="">
                <p className="font-bold text-6xl sm:text-7xl">Contact Us</p>
                <h2 className="text-sm font-light mt-1"><span className="mx-auto group-hover:border-b-8 border-gray-300 duration-500">お問い合わせフォーム</span></h2>
              </div>
            </section>
            <ContactForm />
          </div>
        </section>
      </Layout>
    </>
  )
}

export default Home
