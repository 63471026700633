import React, { useState } from "react";
import { navigate } from "gatsby-link";
import Recaptcha from 'react-google-recaptcha'

const encode = (data) => {
    return Object.keys(data)
        .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
};

const RECAPTCHA_KEY = process.env.SITE_RECAPTCHA_KEY
if (typeof RECAPTCHA_KEY === "undefined") {
    throw new Error(`
  reCAPTCHAキーが登録されていません
  `)
}

const ContactForm = () => {
    const [state, setState] = useState({});
    const recaptchaRef = React.createRef()

    const handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value });
    };

    const handleSubmit = e => {
        e.preventDefault()

        // if recaptcha is not checked, alert the user
        if (recaptchaRef.current.getValue() === "") {
            alert("reCAPTCHAをチェックしてください")
            return
        }

        const form = e.target
        const recaptchaValue = recaptchaRef.current.getValue()
        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({
                "form-name": form.getAttribute("name"),
                "g-recaptcha-response": recaptchaValue,
                ...state,
            }),
        })
            .then(() => navigate(form.getAttribute("action")))
            .catch(error => alert(error))
    }

    return (
        <section className="">
            <form
                name="contact"
                method="post"
                action="/success"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                data-netlify-recaptcha="true"
                onSubmit={handleSubmit}>
                <input type="hidden" name="form-name" value="contact" />
                <p hidden>
                    <label>
                        {" "}
                        <input name="bot-field" onChange={handleChange} />
                    </label>
                </p>

                <div className="relative flex flex-wrap mb-6">
                    <label className="w-full">
                        <input className="relative mb-2 md:mb-0 w-full py-4 pl-4 text-sm border rounded" name="name" type="text" onChange={handleChange} placeholder="例: 田中 太郎" />
                        <span className="absolute top-0 left-0 ml-4 -mt-2 px-1 inline-block bg-white text-gray-500 text-xs">名前</span>
                    </label>
                </div>
                <div className="relative flex flex-wrap mb-6">
                    <label className="w-full">
                        <input className="relative mb-2 md:mb-0 w-full py-4 pl-4 text-sm border rounded" name="email" type="email" onChange={handleChange} placeholder="例:  hello@co.jp" />
                        <span className="absolute top-0 left-0 ml-4 -mt-2 px-1 inline-block bg-white text-gray-500 text-xs">Email</span>
                    </label>
                </div>
                <div className="relative flex flex-wrap mb-6">
                    <label className="w-full">
                        <input className="relative mb-2 md:mb-0 w-full py-4 pl-4 text-sm border rounded" name="phone" type="tel" onChange={handleChange} placeholder="例: 1234-56-7890" />
                        <span className="absolute top-0 left-0 ml-4 -mt-2 px-1 inline-block bg-white text-gray-500 text-xs">電話番号</span>
                    </label>
                </div>
                <div className="relative flex flex-wrap mb-6">
                    <label className="w-full">
                        <input className="relative mb-2 md:mb-0 w-full py-4 pl-4 text-sm border rounded" name="company" type="text" onChange={handleChange} placeholder="例: マコム・プラニング株式会社" />
                        <span className="absolute top-0 left-0 ml-4 -mt-2 px-1 inline-block bg-white text-gray-500 text-xs">会社名</span>
                    </label>
                </div>
                <div className="relative flex flex-wrap mb-6">
                    <label className="w-full">
                        <input className="relative mb-2 md:mb-0 w-full py-4 pl-4 text-sm border rounded" name="subject" type="text" onChange={handleChange} placeholder="例：とろんこアカデミーについての問い合わせ" />
                        <span className="absolute top-0 left-0 ml-4 -mt-2 px-1 inline-block bg-white text-gray-500 text-xs">表題・件名</span>
                    </label>
                </div>
                <div className="relative flex flex-wrap mb-6">
                    <label className="w-full">
                        <textarea className="resize-y relative mb-2 md:mb-0 w-full py-4 pl-4 text-sm border rounded" id={1} name="message" type="message" cols={30} rows={10} onChange={handleChange} placeholder="Message..." defaultValue={""} />
                        <span className="absolute top-0 left-0 ml-4 -mt-2 px-1 inline-block bg-white text-gray-500 text-xs">お問い合わせ内容詳細</span>
                    </label>
                </div>
                <div className="grid justify-center w-full mb-4">
                    <Recaptcha
                        ref={recaptchaRef}
                        sitekey={RECAPTCHA_KEY}
                    />
                </div>
                <button
                    id="submitEnquiry"
                    className={"inline-block w-full px-6 py-4 text-sm ring-1 ring-gray-400 hover:bg-gray-400 hover:text-white rounded-xl duration-500"}
                    type="submit">
                    送信する
                </button>
            </form>
        </section>
    );
};

export default ContactForm;